.app-container {
    .app-header{ 
        .Burger { padding: 0; }   
    }
}
.closed-sidebar {
    .vertical-nav-menu {
        .metismenu-container {
            .metismenu-container {
                transition: padding 300ms;
                &.visible {
                    transition: 300ms;
                    height: 0;
                }
                
            }
        }
    }

    .app-sidebar:hover {
        .vertical-nav-menu {
            .metismenu-container {
                .metismenu-container {
                    &.visible {
                        transition: 300ms;
                        height: auto;
                    }
                }
            }
        }
    }
}

.app-main {
    .app-main__inner {
        padding: 0;
    }
}

.app-main__inner {
    .app-container {
        .app-main {
            padding: 10px;
        }
    }
}

.navbar-dark {
    a, a:active, a:visited {
        color: rgba(255, 255, 255, 0.5);
    }
    a:hover {
        color: rgba(255, 255, 255, 0.75);
        text-decoration: none;
    }
    .dropdown-item {
        a, a:active, a:visited {
            color: rgba(0, 0, 0, 0.5);
        }
        a:hover {
            color: rgba(0, 0, 0, 0.75);
        }
    }
}

.app-theme-white {
    .app-header,.app-footer {
        background: #343a40;
    }
    .app-container {
        .navbar-dark {
            background-color: #3b78e7 !important;
        }
    }
}
.app-main {
    .app-main__outer {
        .app-main__inner {
            .app-main {
                .app-main__outer {
                    padding-left: 0;
                }
            }
        }
    }
}



.dropdown-menu.nav-dropdown-menu {
    position: absolute !important;
    top: 100%  !important;
    left: 0  !important;
    z-index: 1000  !important;
    display: none  !important;
    float: left  !important;
    min-width: 10rem  !important;
    padding: .5rem 0  !important;
    margin: .125rem 0 0  !important;
    font-size: 1rem  !important;
    color: #212529  !important;
    text-align: left  !important;
    list-style: none  !important;
    background-color: #3b78e7;
    box-shadow: none !important;
    border: none !important;
    border-radius: 0 0 .25rem .2rem;
}

.nav-dropdown-menu .dropdown-item * {
    color: #fff !important;
}

.nav-dropdown-menu::before, .nav-dropdown-menu::after {
    content: none !important;
}



@media (min-width: 992px){
    .navbar-expand-lg .navbar-nav .dropdown-menu.nav-dropdown-menu {
        position: absolute !important;
    }
    .dropdown-menu.nav-dropdown-menu {
        background-color: #1952bc !important;
        margin-top: 7px !important;
        // transform: translateY(6px) !important;
    }
}


.navbar-nav .dropdown-menu.nav-dropdown-menu {
    position: static !important;
    float:none !important;
}
.dropdown-menu.nav-dropdown-menu.show {
    display: block !important;
    transform: none !important;
    width: 100% !important;
}


.imageOverride {
    display: block;
    width: 100%;
}


.carousel__dot-group {

    .carousel__dot {
        margin: 0 1px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        padding: 0;
        border: 0;
        background-color: black;
    }
    .carousel__dot--selected {
        background-color: gray;
    }
}

html, body, #root {
    width: 100%;
    height: 100%;
}

.brandContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;

    .brand {
        display: flex;
        width: 100%;
        background: linear-gradient( to right, #000000 0, #000000 50%, #facd01 50%, #facd01 85%, #d8262f 85%, #d8262f )no-repeat;
        height: 7px;
    }
    a {
        color: inherit;
        text-decoration: none;
        padding-right: 10px;
    }
}

.appBody {
    width:100%;
    height: 100%;
    overflow: auto;
    padding: 10px;
}

.googleSignInDark {
    background-image: url('./images/btn_google_signin_dark_normal_web@2x.png');
    background-repeat: no-repeat;
    margin: 0; 
    padding: 0;
    width: 191px;
    height: 46px;
    background-size: contain;
    &:focus,&:hover {
        background-image: url('./images/btn_google_signin_dark_focus_web@2x.png');
        cursor: pointer;
    }
    &:active {
        background-image: url('./images/btn_google_signin_dark_pressed_web@2x.png');
        cursor: pointer;
    }
}
.googleSignInLight {
    background-image: url('./images/btn_google_signin_light_normal_web@2x.png');
    background-repeat: no-repeat;
    margin: 0; 
    padding: 0;
    width: 191px;
    height: 46px;
    background-size: contain;
    &:focus, &:hover {
        background-image: url('./images/btn_google_signin_light_focus_web@2x.png');
        cursor: pointer;
    }
    &:active {
        background-image: url('./images/btn_google_signin_light_pressed_web@2x.png');
        cursor: pointer;
    }
}


.qc-color { 
    border:1px solid black; 
    background-color:#eec200!important;
    color:black;
    font-weight: bold;
}
.qa-color { 
    border:1px solid black; 
    background-color:#d8262f!important;
    color:white;
    font-weight: bold;
}


#lightboxBackdrop {
    z-index: 1000 !important;
}


label.form-check-label {
    input[type="checkbox"] {
        width: 20px;
        height: 20px;
        margin-top: 2px !important;
        margin-left: -25px;
    }
}
div.form-check {
    input[type="checkbox"] {
        width: 20px;
        height: 20px;
        margin-top: -5px;
        margin-left: -25px;
    }
}